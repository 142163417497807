import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/icons-material/Close.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/icons-material/SmsFailed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/icons-material/TaskAlt.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Box/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Dialog/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/DialogContent/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/DialogTitle/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/IconButton/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Skeleton/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Snackbar/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Stack/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mui/material/Typography/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/AssessmentList/AssessmentList.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/CustomMuiButton/CustomMuiButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/CustomTextFieldLabel/CustomTextFieldLabel.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/ErrorFallback/ErrorFallback.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/Footer/Footer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/Header/header.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/Header/HeaderType/DefaultHeader/DefaultHeaderClient.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/Header/HeaderType/SimpleType/SimpleHeaderServer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/MainBanner/MainBanner.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/Modal/Modal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/NavigateToBtn/NavigateToBtn.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/TermsDialog/TermsDialog.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/shared/UserInputField/UserInputField.tsx")